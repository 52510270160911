* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  scroll-behavior: smooth;
  font-family: 'Poppins', sans-serif;

  --black: #232b2b;
  --white: #f8f8f8;
  --darkBlue: #0a192f;
}

.app {
  width: 100%;
  overflow: hidden;
}

h1 {
  font-size: 2.2rem;
  color: var(--black);
}

h2 {
  font-size: 1.5rem;
  color: var(--black);
}

h3 {
  font-size: 1.17rem;
  color: var(--black);
}

p {
  font-size: 1rem;
  color: var(--black);
}

i {
  font-size: 2rem;
}

ul {
  font-size: 1rem;
}

@media (min-width: 768px) {
  h1 {
    font-size: 4rem;
  }

  i {
    font-size: 3rem;
  }
}

@media (min-width: 1280px) {
  h1 {
    font-size: 5rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  p {
    font-size: 1.2rem;
  }

  i {
    font-size: 4rem;
  }

  ul {
    font-size: 1.2rem;
  }
}